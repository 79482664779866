import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
//import {Autocomplete,  createFilterOptions } from "../_components/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation, Trans } from "react-i18next";
import { Popper } from "@mui/material";

const filter = createFilterOptions();

export default function SelectTheme(props) {
  const { data, item, onChange } = props;

  const [value, setValue] = React.useState(item);

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (item !== value) setValue(item);
  }, [item, value]);

  return (
    <Tooltip title={<span style={{ fontSize: 20 }}>{value}</span>} arrow>
      <Autocomplete
        style={{flexGrow:1}}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            onChange(newValue, value);
            setValue(newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            onChange(newValue.inputValue, value);
            setValue(newValue.inputValue);
          } else {
            onChange(newValue, value);
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== "" && !isExisting) {
            filtered.push(inputValue);
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="theme-selector"
        options={data}
        slotProps={{
          // Пропсы для Popper компонента
          popper: {
            placement: "right-end",
          },

          // Пропсы для Paper компонента (контейнер выпадающего списка)
          paper: {
            elevation: 8,
            sx: { backgroundColor: "grey.100", marginLeft: 24, width: 300 },
          },
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option;
        }}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        freeSolo
        //disablePortal
        renderInput={(params) => (
          <TextField {...params} label={t("select_theme")} />
        )}
      />
    </Tooltip>
  );
}

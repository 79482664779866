import React from "react";
import i18next from "i18next";
import { reduxLoader } from "../_services/reduxLoader.service";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { userActions, appActions } from "../_actions";
import { confService } from "../_services";

class ConferenceNotSelected extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conferenceId: 0,
    };
  }

  componentDidMount() {
    if (this.props.forStudent) {
      reduxLoader.studentConferences();
    } else {
      reduxLoader.orgConferences();
    }
  }

  prepareConferencesOptions = () => {
    let options = { 0: i18next.t("choose_conference") };
    const conferences = this.props.forStudent
      ? this.props.studentConferences
      : this.props.orgConferences;
    conferences &&
      conferences.forEach(function (value) {
        options[value.id] = value.name;
      });
    return options;
  };

  render() {
    const options = this.prepareConferencesOptions();
    let current = this.state.conferenceId;
    if (
      !current &&
      this.props.user.selectedConf &&
      this.props.user.selectedConf.id
    ) {
      current = this.props.user.selectedConf.id;
    }

    return (
      <div className="conference-not-selected">
        <div>
          <label htmlFor="text1" className="form__label">
            {i18next.t(
              current ? "choose_conference" : "conference_is_not_selected"
            )}
          </label>
          <br />
          <Select
            margin="dense"
            value={current}
            label="label"
            style={{ maxWidth: "50vw", overflowWrap: "break-word" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
            onChange={(e) => {
              if (e.target.value) {
                confService.confInfo(
                  e.target.value,
                  (data) => {
                    if (data.status === 200) {
                      this.props.dispatch(
                        userActions.set_conf({ ...data.data })
                      );
                      if (this.props.onChange) {
                        this.props.onChange(e.target.value);
                      }
                    }
                  },
                  (error) => {
                    /*
                    this.props.dispatch(
                      userActions.set_error(
                        i18next.t("Unknown error. Please contact the support")
                      )
                    );
                    */
                  }
                );
                this.props.dispatch(
                  appActions.setpage(this.props.page, e.target.value)
                );
              }
              this.setState({ conferenceId: e.target.value });
            }}
          >
            {Object.entries(options).length !== 0 &&
              Object.keys(options).map((item, index) => (
                <MenuItem
                  value={item}
                  style={{ maxWidth: "50vw", overflowWrap: "break-word" }}
                >
                  {options[item]}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { orgConferences, studentConferences, user } = state;

  return {
    orgConferences,
    studentConferences,
    user,
  };
}

const connectedConferenceNotSelected = connect(mapStateToProps)(
  ConferenceNotSelected
);
export { connectedConferenceNotSelected as ConferenceNotSelected };

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";

const filter = createFilterOptions();

export default function SelectSpeaker(props) {
  const { data, item, onChange, text_select_speaker } = props;

  const [value, setValue] = React.useState(item);

  React.useEffect(() => {
    if (item != value) setValue(item);
  },[item,value]);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue(newValue);
          onChange(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue(newValue.inputValue);

          onChange(newValue.inputValue);
        } else {
          setValue(newValue);
          onChange(newValue);
        }
      }}
      style={{flexGrow:1}}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== "" && !isExisting) {
          filtered.push(inputValue);
        }

        return filtered;
      }}
      
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="theme-selector"
      options={data}
      slotProps={{
        // Пропсы для Popper компонента
        popper: {
          placement: "right-end",
        },

        // Пропсы для Paper компонента (контейнер выпадающего списка)
        paper: {
          elevation: 8,
          sx: { backgroundColor: "grey.100", marginLeft: 30, width: 300 },
        },
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option;
      }}
      renderOption={(props, option) => <li {...props}>{option}</li>}
      //disablePortal
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={text_select_speaker} />
      )}
    />
  );
}

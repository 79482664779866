import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18next from "i18next";

import { userActions, appActions } from "../_actions";
import ClientConfItem from "./ClientConfItem";
import { store } from "../_helpers";

import ruFlag from "../img/ru.png";
import enFlag from "../img/en.png";
import cnFlag from "../img/cn.png";

// Базовые стили
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media (min-width: 800px) {
    /* Добавьте медиа-запросы здесь */
  }
`;

const LayoutContains = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Lang = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LangBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
`;

const LangFlag = styled.img`
  width: 24px;
  height: 24px;
`;

const LayoutTitle = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
`;

const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
`;

const FormGroupRow = styled.div`
  display: flex;  
  flex-direction: column;
  gap: 10px;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
`;

const FormInput = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
`;

const Btn = styled.button`
  padding: 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  margin-left: 20px;
`;

const ConfList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  list-style: none;
  padding: 0;

  @media (min-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

class ClientListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPublic: false,
      start_date: "",
      end_date: "",
      isNoMenu: false,
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    let token = localStorage.getItem("token");

    if (!token) {
      this.setState({ isPublic: true });
    }

    if (window.location.href.indexOf("/events") >= 0)
      this.setState({ isNoMenu: true });

    let lang = localStorage.getItem("navic_lang") ?? "ru";

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });

    this.setState({ lang: this.props.app.lang });

    this.props.dispatch(userActions.conf_student_list());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.props.dispatch(userActions.conf_student_list());
    }
  }

  storeChange = (e) => {};

  onItemClick = (e) => {};

  onItemEdit = (e) => {
    if (!this.state.isPublic) {
      this.props.dispatch(userActions.set_conf(e));
      this.props.dispatch(appActions.setpage("conf_view", e.id));
    } else {
      this.props.dispatch(userActions.set_conf(e));
      this.props.dispatch(appActions.setpage("event", e.id));
    }
  };

  onItemDelete = (e) => {};

  changeDate = (index, event) => {
    if (index === 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  resetDateFilter = () => {
    this.setState({ end_date: "", start_date: "" });
    this.forceUpdate();
  };

  changeLang = () => {
    let { lang } = this.state;
    if (lang == "ru") lang = "en";
    else if (lang == "en") lang = "cn";
    else lang = "ru";

    localStorage.setItem("navic_lang", lang);
    this.setState({ lang });

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });
  };

  render() {
    const self = this;
    const { start_date, end_date, lang, isNoMenu } = this.state;
    const { user } = this.props;

    let isMobile = window.innerWidth < 800;

    return (
      <Layout>
        <LayoutContains>
          {isNoMenu && (
            <Lang>
              <LangBtn onClick={this.changeLang}>
                {lang == "ru" && <LangFlag src={ruFlag} alt="" />}
                {lang == "en" && <LangFlag src={enFlag} alt="" />}
                {lang == "cn" && <LangFlag src={cnFlag} alt="" />}
                <span style={{ fontSize: 16 }}>{lang}</span>
              </LangBtn>
            </Lang>
          )}
          <LayoutTitle>{i18next.t("confs_list")}</LayoutTitle>
          <DateWrap>
            <label htmlFor="start">{i18next.t("date_from")}</label>
            <FormInput
              type="date"
              id="start"
              value={start_date}
              name="trip-start"
              onChange={(e) => this.changeDate(0, e)}
            />
          </DateWrap>

          <FormGroupRow>
            <label htmlFor="end">{i18next.t("date_to")}</label>
            <FormInput
              type="date"
              id="end"
              value={end_date}
              name="trip-start"
              onChange={(e) => this.changeDate(1, e)}
            />
            <Btn onClick={this.resetDateFilter}>{i18next.t("reset_dates")}</Btn>
          </FormGroupRow>
        </LayoutContains>

        <ConfList>
          {user.conflist_student.length > 0 &&
            user.conflist_student
              .filter(
                (e) => !start_date || e.start_time.substr(0, 10) > start_date
              )
              .filter((e) => !end_date || e.end_time.substr(0, 10) < end_date)
              .map((item, index) => (
                <ClientConfItem
                  data={item}
                  key={index}
                  onClick={() => self.onItemClick(item.id)}
                  onEdit={() => self.onItemEdit(item)}
                  onDelete={() => self.onItemDelete(item.id)}
                  isMobile={isMobile}
                />
              ))}
        </ConfList>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedClientListPage = connect(mapStateToProps)(ClientListPage);
export { connectedClientListPage as ClientListPage };